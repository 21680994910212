
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Container from "../../components/Structure/Container.vue";
import { Header, Next } from "../../components/Questions";

export default defineComponent({
  name: "EmergencyContraceptionIntroduction",
  components: {
    Container,
    Header,
    Next,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const next = () => {
      store.commit("startQuiz");
      router.push("1");
    };

    return {
      next,
    };
  },
});
